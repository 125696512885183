<script lang="ts">
/** 覚醒アイコンのURL。（実際はBASE64） */
const base64Table = [
  null,
  require('../assets/image/drop/01.png'),
  require('../assets/image/drop/02.png'),
  require('../assets/image/drop/03.png'),
  require('../assets/image/drop/04.png'),
  require('../assets/image/drop/05.png'),
  require('../assets/image/drop/06.png'),
  require('../assets/image/drop/07.png'),
  require('../assets/image/drop/08.png'),
  require('../assets/image/drop/09.png'),
  require('../assets/image/drop/10.png'),
  require('../assets/image/drop/11.png'),
  require('../assets/image/drop/12.png'),
  require('../assets/image/drop/13.png'),
];

/** アイコン画像のURLを取得する。 */
export function getSrc(no: number) {
  return base64Table[no];
}

/** 覚醒アイコンを表示するコンポーネント。 */
export default {
};
</script>
